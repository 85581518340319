import "./Button.scss";

interface Props {
  className: string;
  onClick: any;
  name: string;
}

const Button = (props: Props) => {
  return (
    <button
      className={`basic-button ${props.className || "left"}`}
      onClick={props.onClick}
    >
      {props.name || "button"}
    </button>
  );
};
export default Button;
