import { useState } from "react";
import userPassEyeClose from "./../../assets/svgs/passEyeClose.svg";
import userPassEyeOpen from "./../../assets/svgs/passEyeOpen.svg";
import "./Input.scss";

const Input = ({
  type,
  placeholder,
  label,
  name,
  value,
  onChange,
  disabled,
  id,
  defaultValue,
  onBlur,
  style,
  required,
  min,
  max,
  step,
  error,
  src,
  maxLength,
  minLength,
}: any) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="input-class-wrapper">
      <div className="input-label-div">
        <label className="input-label">{label}</label>
      </div>
      <div className="input-class-container" style={style}>
        <img src={src} />
        <input
          className="input-class"
          type={type === "password" ? (showPassword ? "text" : type) : type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          id={id}
          defaultValue={defaultValue}
          onBlur={onBlur}
          required={required}
          min={min}
          max={max}
          step={step}
          maxLength={maxLength}
          minLength={minLength}
        />
        {type === "password" && (
          <div onClick={togglePassword}>
            {showPassword ? (
              <img src={userPassEyeOpen} />
            ) : (
              <img src={userPassEyeClose} />
            )}
          </div>
        )}
      </div>
      {error ? (
        <div className="inputText-error-container">
          <img
            className="inputText-img"
            style={{ verticalAlign: "middle" }}
            src={require("./../../assets/pngs/remove.png")}
            alt=""
          />
          <span>{error}</span>
        </div>
      ) : null}
    </div>
  );
};

export default Input;
