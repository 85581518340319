import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RedCross } from "../../assets";
import Header from "../Header/Header";
import { DeletePopup } from "../Popup/Popup";
import Sidebar from "../Sidebar/Sidebar";
import "./General.scss";

interface GeneralProps {
  Component: any;
}

const General: FC<GeneralProps> = ({ Component }: any) => {
  const [isClick, setIsClick] = useState(false);
  const contentClass = "gen-component-div-width";
  const [isLogout, setIsLogout] = useState(false);
  const navigation = useNavigate();

  return (
    <div className="">
      <div className="">
        <Sidebar isClick={isClick} setIsLogout={setIsLogout} />
      </div>
      <div className="gen-header-div">
        <Header isClick={isClick} setIsClick={setIsClick} />
      </div>
      <div className={`gen-component-div ${isClick ? contentClass : ""} `}>
        <Component isClick={isClick} setIsLogout={setIsLogout} />
      </div>
      {isLogout ? (
        <DeletePopup
          deleteContent={
            <div className="del-pop">
              <div className="del-pop-main">
                <img className="del-pop-icon" src={RedCross} />
              </div>
              <div className="del-pop-text-div">
                <span className="del-pop-text">
                  Are you sure want to Logout? <br />
                </span>
              </div>
              <div className="del-pop-btn">
                <button
                  className="del-pop-btn-yes"
                  onClick={() => navigation("/")}
                >
                  Yes
                </button>
                <button
                  className="del-pop-btn-no"
                  onClick={() => setIsLogout(false)}
                >
                  No
                </button>
              </div>
            </div>
          }
        />
      ) : null}
    </div>
  );
};

export default General;
