import { useState } from "react";
import { PasswordEyeClose, PasswordEyeOpen } from "../../assets";
import "./InputText.css";

interface InputTextProps {
  type?: any;
  placeholder?: string;
  value?: string;
  onChange?: (value: any) => void;
  className?: string;
  label?: string;
  disabled?: boolean;
  error?: any;
  src?: any;
  style?: any;
  maxLength?: number;
}

function InputText({
  type,
  placeholder,
  value,
  onChange,
  className,
  label,
  disabled,
  error,
  src,
  style,
  maxLength,
}: InputTextProps) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      style={style}
      className={`inputText-input-wrapper ${className ? className : ""}`}
    >
      <label className="inputText-label" htmlFor={label}>
        {label}
      </label>
      <div className="inputText-input-container">
        <img src={src} />
        <input
          className="inputText-input"
          type={type === "password" ? (showPassword ? "text" : type) : type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          maxLength={maxLength}
        />
        {type === "password" && (
          <div onClick={togglePassword}>
            {showPassword ? (
              <img src={PasswordEyeOpen} />
            ) : (
              <img src={PasswordEyeClose} />
            )}
          </div>
        )}
      </div>
      {error ? (
        <div className="inputText-error-container">
          <img
            className="inputText-img"
            style={{ verticalAlign: "middle" }}
            src={require("./../../assets/pngs/remove.png")}
            alt=""
          />
          <span>{error}</span>
        </div>
      ) : null}
    </div>
  );
}

export default InputText;
