import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteIcon, RedCross } from "../../assets";
import {
  AddBrands,
  Button,
  DeletePopup,
  DropDown,
  Input,
} from "../../components";
import { ApiCall } from "../../config";
import { ValidateMobile, ValidateName, ValidatePassword } from "../../helpers";
import { ValidateGst, ValidatePan } from "../../helpers/Validators";
import { AlertType, alertService } from "../../utils/alert.service";
import UserComponent from "./UserComponent";

function UserController({ isClick }: any) {
  const navigation = useNavigate();
  const initialValues = {
    gst: "",
    firstname: "",
    lastname: "",
    phonenumber: "",
    pancard: "",
    address: "",
    usertype: "",
    password: "",
    conpassword: "",
    businessname: "",
  };
  const [brandData, setBrandData] = useState(initialValues);
  const [errors, setErrors] = useState<any>(initialValues);
  const [data, setdata] = useState<any>([]);
  const [selectedPage, setSelectedPage] = useState<any>(1);
  const [size, setSize] = useState(10);
  const [openBrandForm, setOpenBrandForm] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [openEditBrandForm, setOpenEditBrandForm] = useState(false);
  const [isDeletePopOpen, setIsDeletePopOpen] = useState(false);
  const [delValue, setDelValue] = useState<any>("");
  const [sortData, setSortData] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [editItem, setEditItem] = useState<any>(initialValues);
  const [isLoading, setIsLoading] = useState(false);

  const AddRetailerApi = async () => {
    try {
      const response = await ApiCall({
        endpoint: "registration/byadmin",
        method: "POST",
        data: {
          gst: brandData.gst,
          firstname: brandData.firstname,
          lastname: brandData.lastname,
          phonenumber: brandData.phonenumber,
          pancard: brandData.pancard,
          address: brandData.address,
          usertype: brandData.usertype,
          password: brandData.password,
          businessname: brandData.businessname,
        },
      });
      if (response?.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response?.data?.message,
        });

        RetailerListApi();
        openBrandFormClicker();
        setBrandData(initialValues);
        setErrors(initialValues);
      }
    } catch (error: any) {
      if (error?.data?.message) {
        alertService.alert({
          type: AlertType.Error,
          message: error?.data?.message,
        });
      }
    }
  };

  const RetailerListApi = async () => {
    setIsLoading(true);
    const response = await ApiCall({
      endpoint: "user/list",
      method: "POST",
      data: {
        limit: size,
        page: selectedPage?.selected ?? 1,
        columnName: sortData,
        order: orderBy ?? "ASC",
        search: searchInput,
      },
    });
    setIsLoading(false);
    setdata(response?.data);
  };

  const editRetailerApi = async () => {
    try {
      const response = await ApiCall({
        endpoint: "/Edituser",
        method: "POST",
        data: {
          user_id: editItem.user_id,
          gst: editItem.gst,
          first_name: editItem.first_name,
          last_name: editItem.last_name,
          pancard: editItem.pancard,
          address: editItem.address,
          user_type: editItem.user_type,
          password: editItem.password,
          business_name: editItem.business_name,
          verification_status: editItem.verification_status,
        },
      });
      if (response?.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response?.data?.message,
        });
        RetailerListApi();
        toggleEditPopup();
      }
    } catch (error: any) {
      if (error?.data?.message) {
        alertService.alert({
          type: AlertType.Error,
          message: error?.data?.message,
        });
      }
    }
  };

  useEffect(() => {
    RetailerListApi();
  }, [size, selectedPage, searchInput, sortData, orderBy]);

  const DeleteUserApi = async () => {
    try {
      const response = await ApiCall({
        endpoint: "delete/user",
        method: "POST",
        data: {
          user_id: delValue?.user_id,
        },
      });
      if (response?.status === 200) {
        RetailerListApi();
        alertService.alert({
          type: AlertType.Success,
          message: response?.data?.message,
        });
      }
    } catch (error: any) {
      if (error?.data?.message) {
        alertService.alert({
          type: AlertType.Error,
          message: error?.data?.message,
        });
      }
    }
  };

  const AddRetailerValidator = () => {
    let newErrors: any = {};
    let isValid: boolean = true;
    const statusFirstName = ValidateName(brandData.firstname);
    const statusLastName = ValidateName(brandData.lastname);
    const statusPhone = ValidateMobile(brandData.phonenumber);
    const statusRetailerType = ValidateName(brandData.usertype);
    const statusAddress = ValidateName(brandData.address);
    const statusPan = ValidatePan(brandData.pancard);
    const statusPassword = ValidatePassword(brandData.password);
    const statusConPassword = ValidatePassword(brandData.conpassword);
    const statusBusinessName = ValidateName(brandData.businessname);
    const statusGst = ValidateGst(brandData.gst);

    if (brandData.gst) {
      if (statusGst) {
        newErrors.gst = statusGst === 1 ? "GST is invalid" : "";
      }
    }

    if (statusFirstName) {
      newErrors.firstname =
        statusFirstName === 1 ? "First Name is Required" : "";
      isValid = false;
    }

    if (statusPassword) {
      newErrors.password =
        statusPassword === 1
          ? "Password is Required"
          : "Please Enter Strong Password";
      isValid = false;
    }

    if (statusConPassword) {
      newErrors.conpassword =
        statusConPassword === 1
          ? "Confirm Password is Required"
          : "Confirm Password is short";

      isValid = false;
    }

    if (brandData.password !== brandData.conpassword) {
      newErrors.conpassword = "Password is not same";
      isValid = false;
    }

    if (statusLastName) {
      newErrors.lastname = statusLastName === 1 ? "Last Name is Required" : "";
      isValid = false;
    }

    if (statusPhone) {
      newErrors.phonenumber =
        statusPhone === 1
          ? "Phone Number is Required"
          : "Phone Number is Invalid";
      isValid = false;
    }

    if (brandData.pancard) {
      if (statusPan) {
        newErrors.pancard = statusPan === 1 ? "Pan Number is invalid" : "";
      }
    }

    if (statusAddress) {
      newErrors.address =
        statusAddress === 1 ? "Address is Required" : "Phone Number is Invalid";
      isValid = false;
    }

    if (statusRetailerType) {
      newErrors.usertype =
        statusRetailerType === 1 ? "User Type is Required" : "";
      isValid = false;
    }

    if (statusBusinessName) {
      newErrors.businessname =
        statusBusinessName === 1 ? "Business Name is Required" : "";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const editRetailerValidator = () => {
    let newErrors: any = {};
    let isValid: boolean = true;
    const statusFirstName = ValidateName(editItem.first_name);
    const statusLastName = ValidateName(editItem.last_name);
    const statusAddress = ValidateName(editItem.address);
    const statusPan = ValidatePan(editItem.pancard);
    const statusBusinessName = ValidateName(editItem.business_name);
    const statusGst = ValidateGst(editItem.gst);

    if (brandData.gst) {
      if (statusGst) {
        newErrors.gst = statusGst === 1 ? "GST is invalid" : "";
      }
    }

    if (statusFirstName) {
      newErrors.first_name =
        statusFirstName === 1 ? "First Name is Required" : "";
      isValid = false;
    }

    if (statusLastName) {
      newErrors.last_name = statusLastName === 1 ? "Last Name is Required" : "";
      isValid = false;
    }

    if (brandData.pancard) {
      if (statusPan) {
        newErrors.pancard = statusPan === 1 ? "Pan Number is invalid" : "";
      }
    }

    if (statusAddress) {
      newErrors.address = statusAddress === 1 ? "Address is Required" : "";
      isValid = false;
    }

    if (editItem.user_type === "---select---") {
      newErrors.user_type = "User Type is Required";
      isValid = false;
    }

    if (statusBusinessName) {
      newErrors.business_name =
        statusBusinessName === 1 ? "Business Name is Required" : "";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const openBrandFormClicker = () => {
    setOpenBrandForm(!openBrandForm);
    setBrandData(initialValues);
    setErrors(initialValues);
  };

  const toggleEditPopup = () => {
    setOpenEditBrandForm(!openEditBrandForm);
  };

  const toggleDeletePopup = () => {
    setIsDeletePopOpen(!isDeletePopOpen);
  };

  const onEditHandler = (value: any) => {
    toggleEditPopup();
    setEditItem(value);
  };

  const onDeleteHandler = (value: any) => {
    setDelValue(value);
    toggleDeletePopup();
  };

  const submitHandler = () => {
    if (AddRetailerValidator()) {
      AddRetailerApi();
    }
  };

  const editProductSubmitHandler = () => {
    if (editRetailerValidator()) {
      editRetailerApi();
    }
  };

  const deleteSubmitHandler = () => {
    DeleteUserApi();
    toggleDeletePopup();
  };

  const handleChangeSearch = (e: any) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const onShortData = (value: any) => {
    if (value !== sortData) {
      setSortData(value);
      setOrderBy("ASC");
    } else {
      setOrderBy((prev) => (prev == "ASC" ? "DESC" : "ASC"));
    }
  };

  const onViewHandler = (value: any) => {
    setSelectedUserData(value);
  };

  return (
    <div>
      <UserComponent
        isLoading={isLoading}
        openBrandForm={openBrandForm}
        navigation={navigation}
        isClick={isClick}
        data={data}
        setSize={setSize}
        openBrandFormClicker={openBrandFormClicker}
        brandData={brandData}
        setBrandData={setBrandData}
        errors={errors}
        setErrors={setErrors}
        onDeleteHandler={onDeleteHandler}
        searchInput={searchInput}
        handleChangeSearch={handleChangeSearch}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        submitHandler={submitHandler}
        onShortData={onShortData}
        size={size}
        onViewHandler={onViewHandler}
        selectedUser={selectedUserData}
        closeUserDetailsPopup={() => setSelectedUserData(null)}
        onEditHandler={onEditHandler}
      />
      {openEditBrandForm ? (
        <AddBrands
          content={
            <div>
              <div className="addCustomer-first">
                <span className="addCustomer-title">UPDATE USER DETAILS</span>
              </div>
              <hr />
              <div className="addretailer-second">
                <div className="addCustomer-second-firstRow">
                  <Input
                    label="GST"
                    type="text"
                    placeholder="GST"
                    maxLength={15}
                    minLength={15}
                    value={editItem?.gst}
                    onChange={(e: any) =>
                      setEditItem((prevValue: any) => ({
                        ...prevValue,
                        gst: e.target.value,
                      }))
                    }
                    error={errors?.gst}
                  />

                  <Input
                    label="First Name"
                    type="text"
                    placeholder="First Name"
                    value={editItem.first_name}
                    onChange={(e: any) =>
                      setEditItem((prevValue: any) => ({
                        ...prevValue,
                        first_name: e.target.value,
                      }))
                    }
                    error={errors?.first_name}
                  />

                  <Input
                    label="Last Name"
                    type="text"
                    placeholder="Last Name"
                    value={editItem.last_name}
                    onChange={(e: any) =>
                      setEditItem((prevValue: any) => ({
                        ...prevValue,
                        last_name: e.target.value,
                      }))
                    }
                    error={errors?.last_name}
                  />
                </div>
                <div className="addCustomer-second-firstRow">
                  <Input
                    label="Pan Number"
                    type="text"
                    placeholder="Pan Number"
                    value={editItem.pancard}
                    onChange={(e: any) =>
                      setEditItem((prevValue: any) => ({
                        ...prevValue,
                        pancard: e.target.value,
                      }))
                    }
                    error={errors?.pancard}
                  />
                  <div style={{ marginTop: "1.4rem" }}>
                    <DropDown
                      style={{ width: "300px" }}
                      label={"User Type"}
                      data={["ADMIN", "VENDOR", "RETAILER"]}
                      value={editItem.user_type}
                      onChange={(e: any) =>
                        setEditItem((prevValue: any) => ({
                          ...prevValue,
                          user_type: e.target.value,
                        }))
                      }
                      error={errors?.user_type}
                    />
                  </div>
                  <Input
                    label="Address"
                    type="text"
                    placeholder="Address"
                    value={editItem.address}
                    onChange={(e: any) =>
                      setEditItem((prevValue: any) => ({
                        ...prevValue,
                        address: e.target.value,
                      }))
                    }
                    // error={errors?.address}
                  />
                </div>
                <div className="addCustomer-second-firstRow">
                  <Input
                    label="Phone Number"
                    type="text"
                    placeholder="Phone Number"
                    value={editItem.phone_number}
                  />
                  <Input
                    label="Business Name"
                    type="text"
                    placeholder="Business Name"
                    value={editItem.business_name}
                    onChange={(e: any) =>
                      setEditItem((prevValue: any) => ({
                        ...prevValue,
                        business_name: e.target.value,
                      }))
                    }
                    error={errors?.business_name}
                  />
                </div>
              </div>
              <hr />
              <div className="addCustomer-second-bottam-btn">
                <Button
                  className="addCustomer-second-bottam-btn-sub"
                  name="Submit"
                  onClick={editProductSubmitHandler}
                />
                <Button
                  className="addCustomer-second-bottam-btn-can"
                  name="Cancel"
                  onClick={toggleEditPopup}
                />
              </div>
            </div>
          }
        />
      ) : null}
      {isDeletePopOpen ? (
        <DeletePopup
          deleteContent={
            <div className="del-pop">
              <div className="del-pop-main">
                <img className="del-pop-icon" src={RedCross} />
              </div>
              <div className="del-pop-text-div">
                <span className="del-pop-text">
                  Are you sure want to Delete User : <br />
                  {delValue?.first_name} {delValue?.last_name} ?
                </span>
              </div>
              <div className="del-pop-btn">
                <button
                  className="del-pop-btn-yes"
                  onClick={deleteSubmitHandler}
                >
                  Yes
                </button>
                <button className="del-pop-btn-no" onClick={toggleDeletePopup}>
                  No
                </button>
              </div>
            </div>
          }
        />
      ) : null}
    </div>
  );
}

export default UserController;
