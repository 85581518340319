import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { ApiCall } from "../../config";
import {
  ValidateLoginPassword,
  ValidateMobile,
  ValidatePassword,
} from "../../helpers";
import { AlertType, alertService } from "../../utils/alert.service";
import LoginComponent from "./LoginComponent";

const LoginController = () => {
  const initialValues = { phone: "", password: "" };
  const [data, setData] = useState(initialValues);
  const [errors, setErrors] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  const loginApi = async () => {
    try {
      setIsLoading(true);
      const response = await ApiCall({
        endpoint: "admin/login",
        method: "POST",
        data: {
          username: data.phone,
          password: data.password,
        },
      });
      if (response?.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response?.data?.message,
        });
        sessionStorage.setItem("auth_token", response?.data.auth_token);
        navigate("/dashboard");
      }
    } catch (error: any) {
      if (error?.data?.message) {
        alertService.alert({
          type: AlertType.Error,
          message: error?.data?.message,
        });
      }
    }
    setIsLoading(false);
  };

  const validator = (formData = data) => {
    let newErrors: any = {};
    let isValid: any = true;
    const statusId = ValidateMobile(formData.phone);
    const statusPassword = ValidateLoginPassword(formData.password);
    if (statusId) {
      newErrors.phone =
        statusId === 1 ? "Phone is Required" : "Phone is Invalid";
      isValid = false;
    }
    if (statusPassword) {
      newErrors.password = statusPassword === 1 ? "Password is Required" : "";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (validator()) {
      loginApi();
    }
  };

  return (
    <div>
      <LoginComponent
        handleSubmit={handleSubmit}
        data={data}
        setData={setData}
        errors={errors}
        isLoading={isLoading}
      />
    </div>
  );
};

export default LoginController;
