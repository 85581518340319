import { AccordionDown, AccordionRight } from "../../assets";
import "./Accordion.scss";

function Accordion({ content, name, active, onClick }: any) {
  return (
    <div className="cl-accordion-item column">
      <div className="accordion-title" onClick={onClick}>
        {active ? (
          <img className="cl-down-arrow" src={AccordionDown} />
        ) : (
          <img className="cl-right-arrow" src={AccordionRight} />
        )}
        <div className="accordion-title-txt">{name}</div>
      </div>
      {active && <div className="accordion-content">{content}</div>}
    </div>
  );
}

export default Accordion;
