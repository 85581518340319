import {
  NumberDropDown,
  Pagination,
  SearchBar,
  TableComp,
} from "../../components";

interface RetailerProps {
  isClick: any;
  data: any;
  handleChangeSearch: (value: any) => void;
  searchInput: any;
  selectedPage: any;
  VerifiedUser: any;
  DeclineUser: any;
  deniedClick: any;
  setSize: any;
  setSelectedPage: any;
  onShortData: any;
  size?: any;
  isLoading: boolean;
}

const DummyData = [
  { key: "No.", value: "No." },
  { key: "First Name", value: "first_name" },
  { key: "Last Name", value: "last_name" },
  { key: "Business Name", value: "business_name" },
  { key: "Phone Number", value: "phone_number" },
  { key: "GST", value: "gst" },
  { key: "Verify Status", value: "verification_status" },
];

function RetailerComponent({
  isClick,
  data,
  handleChangeSearch,
  searchInput,
  selectedPage,
  VerifiedUser,
  DeclineUser,
  deniedClick,
  setSize,
  setSelectedPage,
  onShortData,
  size,
  isLoading,
}: RetailerProps) {
  const listData: any = [];
  {
    data?.result?.map((item: any, index: any) => {
      const list: any = [
        {
          title: "No.",
          data:
            selectedPage === 1
              ? index + 1
              : (selectedPage?.selected - 1) * size + index + 1,
        },
        {
          title: "First Name",
          data: item.first_name,
        },
        {
          title: "Last Name",
          data: item.last_name,
        },
        {
          title: "Business Name",
          data: item.business_name,
        },
        {
          title: "Phone Number",
          data: item.phone_number,
        },
        {
          title: "GST",
          data: item.gst,
        },
        {
          title: "Verify Status",
          data: item,
        },
      ];
      listData.push(list);
    });
  }

  return (
    <div className={` ${isClick ? "business-class-width" : "business-class"} `}>
      <div className="business-class-card card">
        <div className="business-class-top">
          <div className="business-class-top-left">
            <span className="business-top-left-title">RETAILER LIST</span>
            <div className="business-class-top-left-dropdown">
              <NumberDropDown
                data={data}
                onChange={(e: any) => setSize(e.target.value)}
              />
            </div>
          </div>
          <div className="business-class-top-right">
            <SearchBar onChange={handleChangeSearch} value={searchInput} />
          </div>
        </div>
        <div className="business-class-table">
          <TableComp
            listHeaderData={DummyData}
            listData={listData}
            onClick={(value: any) => VerifiedUser(value)}
            declineClick={(value: any) => DeclineUser(value)}
            deniedClick={(value: any) => deniedClick(value)}
            onShortData={(value: any) => onShortData(value)}
            isLoading={isLoading}
          />
        </div>
        {listData.length > 0 ? (
          <div className="business-class-pagination">
            <Pagination
              selectedPage={selectedPage}
              totalCount={data?.count ?? 1}
              onPageChange={(page: number) => setSelectedPage(page)}
              itemsPerPage={4}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default RetailerComponent;
