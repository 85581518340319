function Header({ isClick, setIsClick }: any) {
  const classNameOne = "home-section-width";

  const handleClick = (event: any) => {
    event.preventDefault();
    setIsClick((current: any) => !current);
  };

  return (
    <div>
      <section className={`home-section ${isClick ? "" : classNameOne} `}>
        <div>
          <div className="home-content">
            <i className="bx bx-menu" onClick={handleClick}></i>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Header;
