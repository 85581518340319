import moment from "moment";
import {
  NumberDropDown,
  Pagination,
  SearchBar,
  TableComp,
} from "../../components";

interface ReplaceProductProps {
  isClick: any;
  data: any;
  handleChangeSearch: (value: any) => void;
  searchInput: any;
  selectedPage: any;
  setSize: any;
  setSelectedPage: any;
  onShortData: any;
  size?: any;
  replaceNewProductDetails: (value: any) => void;
  replaceOldProductDetails: (value: any) => void;
  isLoading: boolean;
}

const DummyData = [
  { key: "No.", value: "No." },
  { key: "New Product", value: "new_product" },
  { key: "Old Product", value: "old_product" },
  { key: "Date", value: "created_at" },
  { key: "Action", value: "Action" },
];

function ReplaceProductComponent({
  isClick,
  data,
  handleChangeSearch,
  searchInput,
  selectedPage,
  setSize,
  setSelectedPage,
  onShortData,
  size,
  replaceNewProductDetails,
  replaceOldProductDetails,
  isLoading,
}: ReplaceProductProps) {
  const listData: any = [];
  {
    data?.result?.map((item: any, index: any) => {
      const list: any = [
        {
          title: "No.",
          data:
            selectedPage === 1
              ? index + 1
              : (selectedPage?.selected - 1) * size + index + 1,
        },
        {
          title: "New Product",
          data: item,
        },
        {
          title: "Old Product",
          data: item?.old_product,
        },
        {
          title: "Date",
          data: moment(item.created_at).format("DD-MM-YYYY"),
        },
        {
          title: "Action",
          data: item,
        },
      ];
      listData.push(list);
    });
  }
  return (
    <div className={` ${isClick ? "business-class-width" : "business-class"} `}>
      <div className="business-class-card card">
        <div className="business-class-top">
          <div className="business-class-top-left">
            <span className="business-top-left-title">
              REPLACE PRODUCT LIST
            </span>
            <div className="business-class-top-left-dropdown">
              <NumberDropDown
                data={data}
                onChange={(e: any) => setSize(e.target.value)}
              />
            </div>
          </div>
          <div className="business-class-top-right">
            <SearchBar onChange={handleChangeSearch} value={searchInput} />
          </div>
        </div>
        <div className="business-class-table">
          <TableComp
            isLoading={isLoading}
            listHeaderData={DummyData}
            listData={listData}
            onShortData={(value: any) => onShortData(value)}
            replaceNewProductDetails={(value: any) =>
              replaceNewProductDetails(value)
            }
            onViewHandler={(value: any) => replaceNewProductDetails(value)}
          />
        </div>
        {listData.length > 0 ? (
          <div className="business-class-pagination">
            <Pagination
              selectedPage={selectedPage}
              totalCount={data?.count ?? 1}
              onPageChange={(page: number) => setSelectedPage(page)}
              itemsPerPage={4}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ReplaceProductComponent;
