import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddBrands, Button, Input } from "../../components";
import { ApiCall } from "../../config";
import { ValidateName } from "../../helpers";
import { AlertType, alertService } from "../../utils/alert.service";
import ProductsComponent from "./ProductsComponent";

function ProductController({ isClick }: any) {
  const navigation = useNavigate();
  const initialValues = {
    product_name: "",
    warranty_period_in_months: "",
  };
  const [productData, setProductData] = useState(initialValues);
  const [errors, setErrors] = useState(initialValues);
  const [data, setdata] = useState<any>([]);
  const [selectedPage, setSelectedPage] = useState<any>(1);
  const [size, setSize] = useState(10);
  const [openProductForm, setOpenProductForm] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [openEditProductForm, setOpenEditProductForm] = useState(false);
  const [editItem, setEditItem] = useState(initialValues);
  const [sortData, setSortData] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const AddProductApi = async () => {
    try {
      const response = await ApiCall({
        endpoint: "/products/create",
        method: "POST",
        data: {
          product_name: productData.product_name,
          warranty_period: productData.warranty_period_in_months,
        },
      });
      if (response?.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response?.data?.message,
        });
        ProductListApi();
        setOpenProductForm(!openProductForm);
        setProductData(initialValues);
        setErrors(initialValues);
      }
    } catch (error: any) {
      if (error?.data?.message) {
        alertService.alert({
          type: AlertType.Error,
          message: error?.data?.message,
        });
      }
    }
  };

  const ProductListApi = async () => {
    setIsLoading(true);
    const response = await ApiCall({
      endpoint: "/products/list",
      method: "POST",
      data: {
        limit: size,
        page: selectedPage?.selected ?? 1,
        columnName: sortData,
        order: orderBy ?? "ASC",
        search: searchInput,
      },
    });
    setIsLoading(false);
    setdata(response?.data);
  };

  useEffect(() => {
    ProductListApi();
  }, [size, selectedPage, searchInput, sortData, orderBy]);

  const AddProductValidator = () => {
    let newErrors: any = {};
    let isValid: boolean = true;
    const statusProductName = ValidateName(productData.product_name);
    const statusProductWarrant = ValidateName(
      productData.warranty_period_in_months
    );

    if (statusProductName) {
      newErrors.product_name =
        statusProductName === 1 ? "Product Name is Required" : "";
      isValid = false;
    }

    if (statusProductWarrant) {
      newErrors.warranty_period_in_months =
        statusProductWarrant === 1 ? "Product Warranty is Required" : "";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const openProductFormClicker = () => {
    setOpenProductForm(!openProductForm);
    setProductData(initialValues);
    setErrors(initialValues);
  };

  const toggleEditPopup = () => {
    setOpenEditProductForm(!openEditProductForm);
  };

  const onEditHandler = (value: any) => {
    toggleEditPopup();
    setEditItem(value);
  };

  const onDeleteHandler = (value: any) => {};

  const submitHandler = () => {
    if (AddProductValidator()) {
      AddProductApi();
    }
  };

  const editProductSubmitHandler = () => {
    AddProductValidator();
  };

  const handleChangeSearch = (e: any) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const onShortData = (value: any) => {
    if (value !== sortData) {
      setSortData(value);
      setOrderBy("ASC");
    } else {
      setOrderBy((prev) => (prev == "ASC" ? "DESC" : "ASC"));
    }
  };

  return (
    <div>
      <ProductsComponent
        openProductForm={openProductForm}
        navigation={navigation}
        isClick={isClick}
        data={data}
        setSize={setSize}
        openProductFormClicker={openProductFormClicker}
        productData={productData}
        setData={setdata}
        setProductData={setProductData}
        errors={errors}
        setErrors={setErrors}
        onEditHandler={onEditHandler}
        onDeleteHandler={onDeleteHandler}
        searchInput={searchInput}
        handleChangeSearch={handleChangeSearch}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        submitHandler={submitHandler}
        onShortData={onShortData}
        size={size}
        isLoading={isLoading}
      />
      {openEditProductForm ? (
        <AddBrands
          content={
            <div>
              <div className="addCustomer-first">
                <span className="addCustomer-title">
                  UPDATE PRODUCT DETAILS
                </span>
              </div>
              <hr />
              <div className="addbrand-second">
                <div className="addCustomer-second-firstRow">
                  <Input
                    label="Product Name"
                    type="text"
                    placeholder="Product Name"
                    value={editItem?.product_name}
                    onChange={(e: any) =>
                      setEditItem((prevValue: any) => ({
                        ...prevValue,
                        product_name: e.target.value,
                      }))
                    }
                    error={errors?.product_name}
                  />

                  <Input
                    label="Warranty (In Months)"
                    type="number"
                    placeholder="Warranty"
                    value={editItem?.warranty_period_in_months}
                    onChange={(e: any) =>
                      setEditItem((prevValue: any) => ({
                        ...prevValue,
                        warranty_period_in_months: e.target.value,
                      }))
                    }
                    error={errors?.warranty_period_in_months}
                  />
                </div>
              </div>
              <hr />
              <div className="addCustomer-second-bottam-btn">
                <Button
                  className="addCustomer-second-bottam-btn-sub"
                  name="Submit"
                  onClick={editProductSubmitHandler}
                />
                <Button
                  className="addCustomer-second-bottam-btn-can"
                  name="Cancel"
                  onClick={toggleEditPopup}
                />
              </div>
            </div>
          }
        />
      ) : null}
    </div>
  );
}

export default ProductController;
