import React from "react";
import ReactPaginate from "react-paginate";
import "./Pagination.scss";

function Pagination({ totalCount, onPageChange }: any) {
  return (
    <div>
      <ReactPaginate
        previousLabel={"🡠ㅤPrevious"}
        nextLabel={"Nextㅤ🡢"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={totalCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={(page) => {
          onPageChange({ ...page, selected: page.selected + 1 });
        }}
        containerClassName={"pagination"}
        // subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </div>
  );
}

export default Pagination;
