function DropDown({
  label,
  onChange,
  data,
  onClick,
  disabled,
  style,
  error,
  value,
}: any) {
  return (
    <div className="ac-inputText-input-wrapper">
      <label className="ac-inputText-label" htmlFor={label}>
        {label}
      </label>
      <select
        className="ac-inputText-input-container"
        style={style}
        onChange={onChange}
        onClick={onClick}
        disabled={disabled}
        value={value}
      >
        <option>---select---</option>
        {data &&
          data?.map((item: any, index: any) => {
            return (
              <option key={index} className="ac-inputText-input" value={item}>
                {item}
              </option>
            );
          })}
      </select>
      {error ? (
        <div className="inputText-error-container">
          <img
            className="inputText-img"
            style={{ verticalAlign: "middle" }}
            src={require("./../../assets/pngs/remove.png")}
            alt=""
          />
          <span>{error}</span>
        </div>
      ) : null}
    </div>
  );
}

export default DropDown;
