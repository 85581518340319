import { useEffect, useState } from "react";
import { ApiCall } from "../../config";
import { AlertType, alertService } from "../../utils/alert.service";
import RetailerComponent from "./RetailerComponent";
import { DeletePopup } from "../../components";
import { RedCross } from "../../assets";

function RetailerController({ isClick }: any) {
  const [data, setdata] = useState<any>([]);
  const [selectedPage, setSelectedPage] = useState<any>(1);
  const [size, setSize] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [isDeletePopOpen, setIsDeletePopOpen] = useState(false);
  const [delValue, setDelValue] = useState<any>("");
  const [sortData, setSortData] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const VerifyUserListApi = async () => {
    setIsLoading(true);
    const response = await ApiCall({
      endpoint: "retailer/list",
      method: "POST",
      data: {
        limit: size,
        page: selectedPage?.selected ?? 1,
        columnName: sortData,
        order: orderBy ?? "ASC",
        search: searchInput,
      },
    });
    setdata(response?.data);
    setIsLoading(false);
  };

  useEffect(() => {
    VerifyUserListApi();
  }, [size, selectedPage, searchInput, orderBy, sortData]);

  const VerifyUserApi = async (value: any) => {
    try {
      const response = await ApiCall({
        endpoint: "verify/retailer",
        method: "POST",
        data: {
          user_id: value?.user_id,
        },
      });
      if (response?.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response?.data?.message,
        });
        VerifyUserListApi();
      }
    } catch (error: any) {
      if (error?.data?.message) {
        alertService.alert({
          type: AlertType.Error,
          message: error?.data?.message,
        });
      }
    }
  };

  const DeclineUserApi = async (value: any) => {
    try {
      const response = await ApiCall({
        endpoint: "denied/user",
        method: "POST",
        data: {
          user_id: value?.user_id,
        },
      });
      if (response?.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response?.data?.message,
        });
        VerifyUserListApi();
      }
    } catch (error: any) {
      if (error?.data?.message) {
        alertService.alert({
          type: AlertType.Error,
          message: error?.data?.message,
        });
      }
    }
  };

  const RevertRetailerApi = async () => {
    try {
      const response = await ApiCall({
        endpoint: "verification/status",
        method: "POST",
        data: {
          user_id: delValue?.user_id,
          verification_status: "PENDING",
        },
      });
      if (response?.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response?.data?.message,
        });
        VerifyUserListApi();
      }
    } catch (error: any) {
      if (error?.data?.message) {
        alertService.alert({
          type: AlertType.Error,
          message: error?.data?.message,
        });
      }
    }
  };

  const handleChangeSearch = (e: any) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const VerifiedUser = (value: any) => {
    VerifyUserApi(value);
  };

  const DeclineUser = (value: any) => {
    DeclineUserApi(value);
  };

  const toggleDeletePopup = () => {
    setIsDeletePopOpen(!isDeletePopOpen);
  };

  const deniedClick = (value: any) => {
    setDelValue(value);
    toggleDeletePopup();
  };

  const deleteSubmitHandler = () => {
    RevertRetailerApi();
    toggleDeletePopup();
  };

  const onShortData = (value: any) => {
    if (value !== sortData) {
      setSortData(value);
      setOrderBy("ASC");
    } else {
      setOrderBy((prev) => (prev == "ASC" ? "DESC" : "ASC"));
    }
  };

  return (
    <div>
      <RetailerComponent
        isClick={isClick}
        data={data}
        handleChangeSearch={handleChangeSearch}
        searchInput={searchInput}
        selectedPage={selectedPage}
        VerifiedUser={VerifiedUser}
        DeclineUser={DeclineUser}
        deniedClick={deniedClick}
        setSize={setSize}
        setSelectedPage={setSelectedPage}
        onShortData={onShortData}
        size={size}
        isLoading={isLoading}
      />
      {isDeletePopOpen ? (
        <DeletePopup
          deleteContent={
            <div className="del-pop">
              <div className="del-pop-main">
                <img className="del-pop-icon" src={RedCross} />
              </div>
              <div className="del-pop-text-div">
                <span className="del-pop-text">
                  Are you sure want to ReVerified : <br />
                  {delValue?.first_name} {delValue?.last_name} ?
                </span>
              </div>
              <div className="del-pop-btn">
                <button
                  className="del-pop-btn-yes"
                  onClick={deleteSubmitHandler}
                >
                  Yes
                </button>
                <button className="del-pop-btn-no" onClick={toggleDeletePopup}>
                  No
                </button>
              </div>
            </div>
          }
        />
      ) : null}
    </div>
  );
}

export default RetailerController;
