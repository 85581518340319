import { AlertType, alertService } from "../utils/alert.service";
import Api from "./interceptors";

interface ApiCallProps {
  endpoint: string;
  data?: any;
  method: "POST" | "GET";
}

export async function ApiCall({ endpoint, data, method }: ApiCallProps) {
  const ApiResponse = await Api({
    url: endpoint,
    method: method,
    data: data,
    headers: {
      "Content-Type": "application/json",
      authorization: sessionStorage.getItem("auth_token"),
    },
  });

  return ApiResponse;
}
