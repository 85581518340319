import React from "react";
import "./Popup.scss";

export const AddBrands = (props: any) => {
  return (
    <div className="addBrand-box-main">
      <div className="addBrand-box">
        <h1>{props.content}</h1>
      </div>
    </div>
  );
};

export const DeletePopup = (props: any) => {
  return (
    <div className="deleteCustomer-box-main">
      <div className="deleteCustomer-box">
        <h1>{props.deleteContent}</h1>
      </div>
    </div>
  );
};
