import {
  AddBrands,
  Button,
  Input,
  NumberDropDown,
  Pagination,
  SearchBar,
  TableComp,
} from "../../components";

interface ProductComponentProps {
  openProductForm: boolean;
  navigation: any;
  isClick: any;
  data: any;
  setSize: any;
  openProductFormClicker: (value: any) => void;
  productData: any;
  setData: (value: any) => void;
  errors: any;
  setErrors: (value: any) => void;
  onEditHandler: (value: any) => void;
  onDeleteHandler: (value: any) => void;
  searchInput: any;
  handleChangeSearch: (value: any) => void;
  selectedPage: any;
  setSelectedPage: (value: any) => void;
  submitHandler: (value: any) => void;
  setProductData: any;
  onShortData: (value: any) => void;
  size?: any;
  isLoading?: boolean;
}

const DummyData = [
  { key: "No.", value: "No." },
  { key: "Product Name", value: "product_name" },
  { key: "Warranty (In Months)", value: "warranty_period_in_months" },
];

function ProductsComponent({
  openProductForm,
  isClick,
  data,
  setSize,
  openProductFormClicker,
  productData,
  errors,
  onDeleteHandler,
  searchInput,
  handleChangeSearch,
  selectedPage,
  setSelectedPage,
  submitHandler,
  setProductData,
  onShortData,
  size,
  isLoading,
}: ProductComponentProps) {
  const listData: any = [];
  {
    data?.result?.map((item: any, index: any) => {
      const list: any = [
        {
          title: "No.",
          data:
            selectedPage === 1
              ? index + 1
              : (selectedPage?.selected - 1) * size + index + 1,
        },
        {
          title: "Product Name",
          data: item.product_name,
        },
        {
          title: "Warranty (In Months)",
          data: item.warranty_period_in_months,
        },
      ];
      listData.push(list);
    });
  }

  return (
    <div className={` ${isClick ? "business-class-width" : "business-class"} `}>
      <div className="business-class-card card">
        <div className="business-class-top">
          <div className="business-class-top-left">
            <span className="business-top-left-title">PRODUCT LIST</span>
            <div className="business-class-top-left-dropdown">
              <NumberDropDown
                data={data}
                onChange={(e: any) => setSize(e.target.value)}
              />
            </div>
          </div>
          <div className="business-class-top-right">
            <Button
              className="addbrand-info-btn"
              name={"Add Product"}
              onClick={openProductFormClicker}
            />
            <SearchBar onChange={handleChangeSearch} value={searchInput} />
          </div>
        </div>
        <div className="business-class-table">
          <TableComp
            isLoading={isLoading}
            listHeaderData={DummyData}
            listData={listData}
            onDeleteHandler={(value: any) => onDeleteHandler(value)}
            onShortData={(value: any) => onShortData(value)}
          />
        </div>
        {listData.length > 0 ? (
          <div className="business-class-pagination">
            <Pagination
              selectedPage={selectedPage}
              totalCount={data?.count ?? 1}
              onPageChange={(page: number) => setSelectedPage(page)}
              itemsPerPage={4}
            />
          </div>
        ) : null}
      </div>
      {openProductForm ? (
        <AddBrands
          content={
            <div>
              <div className="addCustomer-first">
                <span className="addCustomer-title">PRODUCT DETAILS</span>
              </div>
              <hr />
              <div className="addbrand-second">
                <div className="addCustomer-second-firstRow">
                  <Input
                    label="Product Name"
                    type="text"
                    placeholder="Product Name"
                    value={productData?.product_name}
                    onChange={(e: any) =>
                      setProductData((prevValue: any) => ({
                        ...prevValue,
                        product_name: e.target.value,
                      }))
                    }
                    error={errors?.product_name}
                  />
                  <Input
                    label="Warranty (In Months)"
                    type="number"
                    placeholder="Warranty"
                    value={productData.warranty_period_in_months}
                    onChange={(e: any) =>
                      setProductData((prevValue: any) => ({
                        ...prevValue,
                        warranty_period_in_months: e.target.value,
                      }))
                    }
                    error={errors?.warranty_period_in_months}
                  />
                </div>
              </div>
              <hr />
              <div className="addCustomer-second-bottam-btn">
                <Button
                  className="addCustomer-second-bottam-btn-sub"
                  name="Submit"
                  onClick={submitHandler}
                />
                <Button
                  className="addCustomer-second-bottam-btn-can"
                  name="Cancel"
                  onClick={openProductFormClicker}
                />
              </div>
            </div>
          }
        />
      ) : null}
    </div>
  );
}

export default ProductsComponent;
