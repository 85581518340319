import { useLocation, useNavigate } from "react-router-dom";
import { RWarranty, RWarrantySmall } from "../../assets";

function Sidebar({ isClick, setIsLogout }: any) {
  const navigation = useNavigate();
  const { pathname } = useLocation();

  const currentTab = "/" + pathname.split("/").at(1);

  const classSidebar = "close";

  const SidebarData = [
    {
      id: 1,
      name: "Dashboard",
      icon: "bx bxs-dashboard",
      path: "/dashboard",
    },
    {
      id: 2,
      name: "User",
      icon: "bx bx-buildings",
      path: "/users",
    },
    {
      id: 3,
      name: "Products",
      icon: "bx bx-category",
      path: "/products",
    },
    {
      id: 4,
      name: "Warranty",
      icon: "bx bx-scan",
      path: "/warranty",
    },
    {
      id: 5,
      name: "Replace Products",
      icon: "bx bx-duplicate",
      path: "/replace-products",
    },
    {
      id: 6,
      name: "Retailer",
      icon: "bx bx-user-plus",
      path: "/retailer",
    },
    {
      id: 7,
      name: "Log out",
      icon: "bx bx-log-out",
    },
  ];

  return (
    <div>
      <div className={`sidebar ${isClick ? "" : classSidebar} `}>
        <div className="logo-details">
          {isClick ? (
            <img className="classImageFull" src={RWarranty} />
          ) : (
            <img className="classImage" src={RWarrantySmall} />
          )}
        </div>
        <ul className="nav-links">
          {SidebarData?.map((item, index) => (
            <div>
              <li
                className={`${currentTab === item?.path ? "active" : ""}`}
                onClick={() =>
                  index === 6
                    ? setIsLogout(true)
                    : navigation(item?.path ? item?.path : "")
                }
              >
                <a>
                  <i className={`${item.icon}`} style={{ color: "white" }}></i>
                  <span className="link_name">{item.name}</span>
                </a>
                <ul className="sub-menu blank">
                  <li>
                    <a className="link_name">{item.name}</a>
                  </li>
                </ul>
              </li>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
