import React from "react";
import DashboardComponent from "./DashboardComponent";

function DashboardController() {
  return (
    <div>
      <DashboardComponent />
    </div>
  );
}

export default DashboardController;
